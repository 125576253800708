import React from 'react';
import { graphql } from 'gatsby';
import Layout from '@layouts';
import { Banners, SEO, Articles } from '@components';

const { Banner } = Banners;

const Index = ({ data }) => (
  <Layout
    bannerChildren={
      <Banner
        title='Articles'
        description="Read our articles on African history, learn about Africans' contributions to STEM fields, and stay updated on all the exciting developments happening on the continent."
      />
    }
  >
    <SEO title='ARTICLES' />
    <Articles
      articleData={data}
      description="Read our articles on African history, learn about Africans' contributions to STEM fields, and stay updated on all the exciting developments happening on the continent."
    />
  </Layout>
);

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { frontmatter: { content_type: { eq: "article" } } }
      sort: { fields: [frontmatter___date, frontmatter___title], order: [DESC, ASC] }
    ) {
      edges {
        node {
          frontmatter {
            content_type
            slug
            title
            subject
            excerpt
            author
            photo_credit
            category
            date
            image_url {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
            image_1 {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Index;
